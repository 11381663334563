import gql from 'graphql-tag'
import userFragment from './UserFragment.js'

export const CHECK_CADIDATE_ACCOUNT_MUTATION = gql`
  mutation CheckCandidateAccount($email: String!, $name: String!, $telephone: String!, $locale:String) {
    checkCandidateAccount(email: $email, name: $name, telephone: $telephone,locale: $locale)
  }
`

export const CREATE_CANDIDATE_ACCOUNT_MUTATION = gql`
  mutation CreateCandidateAccount(
    $email: String!, 
    $lastName: String!, 
    $name: String!, 
    $telephone: String!, 
    $telephone2: String, 
    $address: String, 
    $password: String!, 
    $interviewId: ID, 
    $sendedEmail: String
    $vacancyId: ID
  ) {
    createCandidateAccount(
      email: $email, 
      lastName: $lastName, 
      name: $name, 
      telephone: $telephone, 
      telephone2: $telephone2, 
      address: $address, 
      password: $password, 
      interviewId: $interviewId, 
      sendedEmail: $sendedEmail, 
      vacancyId: $vacancyId
    ) {
      token
      user {
        ...${userFragment}
      }
    }
  }
`
export const LOGIN_CANDIDATE_IMPORTED_MUTATION = gql`
  mutation LoginForCandidateInmported($candidateId: ID!, $email: String!, $password: String!) {
    loginForCandidateInmported(candidateId: $candidateId, email: $email, password: $password) {
      token
      user {
        ...${userFragment}
      }
    }
  }
`
export const ACTIVE_CANDIDATE = gql`
  mutation activeAccount {
    activeAccount
  }
`