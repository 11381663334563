<template>
  <v-container fill-height class="fill-height wena" fluid>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="4" lg="4" xl="4">
        <v-system-bar color="secondary" dark class="pb-7">
          <v-spacer></v-spacer>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn text color v-on="on">
                <flag :iso="currentFlag" v-bind:squared="false" />
                <v-icon right color="primary">mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                v-for="(entry, index) in languagess"
                :key="`${index}010`"
                @click="changeLocale(entry)"
              >
                <v-list-item-title>
                  <flag :iso="entry.flag" v-bind:squared="false" />
                  &nbsp;{{ entry.title }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-system-bar>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="isRegister ? checkAccount() : createAccount()"
        >
          <v-card class="">
            <v-row justify="center">
              <v-col cols="4" md="5">
                <v-responsive class="pt-10">
                  <v-img width="300px" src="/logo-black.jpeg"></v-img>
                </v-responsive>
              </v-col>
            </v-row>
            <!-- <v-card-title class="subtitle-1 text-center justify-center py-2">
              <div v-html="step.title">{{ step.title }}</div>
              <div v-show="showError" class="error--text text-center">{{ error }}</div>
            </v-card-title> -->
            <div v-html="step.title" class="text-center subtitle-2 px-0 pt-4">
              {{ step.title }}
            </div>
            <!-- <div v-show="showError" class="error--text text-center">{{ error }}</div> -->
            <div class="px-4">
              <v-alert
                v-show="showError"
                dense
                outlined
                type="error"
                class="text-center"
              >
                {{ $t(error) }}
              </v-alert>
            </div>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="12">
                  <v-row v-if="isRegister">
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="candidate.name"
                        :label="$t('name')"
                        outlined
                        type="text"
                        dense
                        required
                        hide-details
                        :rules="requiredRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="candidate.lastName"
                        :label="$t('last_name')"
                        outlined
                        type="text"
                        dense
                        required
                        hide-details
                        :rules="requiredRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="candidate.email"
                        label="E-mail"
                        outlined
                        type="email"
                        dense
                        required
                        hide-details
                        :rules="emailRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="pb-0">
                      <vue-tel-input-vuetify
                        v-model="candidate.telephone"
                        :label="$t('form_user_phone')"
                        persistent-hint
                        outlined
                        dense
                        required
                        :rules="requiredRules"
                        @input="onInputPhone"
                      >
                      </vue-tel-input-vuetify>
                    </v-col>
                    <v-col cols="12" md="6" class="pb-0">
                      <vue-tel-input-vuetify
                        v-model="candidate.telephone2"
                        :label="`${$t('Whatsapp')}`"
                        persistent-hint
                        outlined
                        dense
                        @input="onInputPhone2"
                      >
                      </vue-tel-input-vuetify>
                    </v-col>
                    <v-col cols="12" md="12" class="pt-0">
                      <v-text-field
                        v-model="candidate.address"
                        :label="$t('address')"
                        outlined
                        type="text"
                        dense
                        required
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="candidate.password"
                        :label="$t('password')"
                        outlined
                        type="password"
                        dense
                        required
                        hide-details
                        :rules="requiredRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="repeatPassword"
                        :label="$t('confirm_password')"
                        outlined
                        type="password"
                        dense
                        required
                        hide-details
                        :rules="repeatPasswordRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-checkbox
                        v-model="checkbox"
                        class="no-gutters pt-0"
                        :rules="[(v) => !!v || $t('agree_continue')]"
                      >
                        <template v-slot:label>
                          <div>
                            {{ $t("read_agree") }}
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <a
                                  target="_blank"
                                  @click="Terms_Conditions"
                                  @click.stop
                                  v-on="on"
                                >
                                  {{ $t("terms_conditions") }}
                                </a>
                                <!-- <a href="https://webpage2.whirelab.com/policy.html#w-tabs-0-data-w-pane-0" target="_blank" v-on="on">
                                  Termos e Condições
                                </a> -->
                              </template>
                              {{ $t("read_terms_conditions") }}
                            </v-tooltip>
                          </div>
                        </template>
                      </v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row v-else class="px-12">
                    <v-col cols="12" md="12" class="my-4 px-12">
                      <v-text-field
                        v-model.number="verificationCodeEnter"
                        :label="$t('enter_code')"
                        outlined
                        dense
                        required
                        hide-details
                        :rules="requiredRules"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="12" class="py-0 px-12">
                  <v-btn
                    :disabled="isLoading"
                    :loading="isLoading"
                    small
                    type="submit"
                    block
                    color="primary"
                    large
                    >{{ step.button }}</v-btn
                  >
                </v-col>
                <v-col cols="12" md="12" class="text-center py-6">
                  <div v-if="!isRegister && !resended">
                    {{ $t("did_not_receive_the_code") }}
                    <a @click="reCheckAccount()" class="text-captalize">
                      {{ $t("resend") }}
                    </a>
                  </div>
                  <div v-if="resended" class="primary--text">
                    {{ $t("code_resent_successfully") }}
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
    <terms-conditions :dialog="dialogTerms" @close="dialogTerms = false" />
  </v-container>
</template>

<script>
import {
  CHECK_CADIDATE_ACCOUNT_MUTATION,
  CREATE_CANDIDATE_ACCOUNT_MUTATION,
} from "./../graphql/Mutation";
import Candidate from "@/models/Candidate";
import TermsConditions from "../components/TermsConditions.vue";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue";
import { formatError } from "@/utils";
import { LOGIN_MUTATION } from "../../auth/graphql/login";
import { mapActions } from "vuex";
import { GC_AUTH_TOKEN, GC_USER_ID } from "@/constants/settings";
export default {
  components: { TermsConditions, VueTelInputVuetify },
  name: "CandidateRegister",
  data: () => ({
    candidate: new Candidate(),
    checkbox: false,
    currentFlag: "",
    dialogTerms: false,
    error: undefined,
    isLoading: false,
    isRegister: true,
    repeatPassword: "",
    showError: false,
    valid: true,
    verificationCode: undefined,
    verificationCodeEnter: undefined,
    phone: {
      number: "",
      valid: false,
      country: undefined,
    },
    phone2: {
      number: "",
      valid: false,
      country: undefined,
    },
    resended: false,
  }),
  created() {
    this.currentFlag = localStorage.getItem("flag")
      ? localStorage.getItem("flag")
      : "pt";
  },
  computed: {
    emailRules() {
      return [
        (v) => !!v || `${this.$t("required_field")}`,
        (v) => /.+@.+\..+/.test(v) || `${this.$t("enter_valid_email")}`,
      ];
    },
    languagess: function () {
      return [
        { flag: "gb", language: "en", title: "English" },
        // { flag: "fr", language: "fr", title: "Français" },
        { flag: "pt", language: "pt", title: "Português" },
      ];
    },
    repeatPasswordRules() {
      return [
        (v) => v === this.candidate.password || `${this.$t("diff_password")}`,
      ];
    },
    requiredRules() {
      return [(v) => !!v || `${this.$t("required_field")}`];
    },
    step() {
      return this.isRegister
        ? {
            title: `${this.$t("register")}`,
            button: `${this.$t("btn_register")}`,
          }
        : {
            title: `${this.$t(
              "we_send_email_candidate"
            )}: <a class="text-decoration-underline">${
              this.candidate.email
            }</a><br />${this.$t("we_send_email_spam")}`,
            button: `${this.$t("btn_submit")}`,
          };
    },
  },
  methods: {
    ...mapActions({
      setUserState: "auth/setUser",
    }),
    onInputPhone(formattedNumber, { number, valid, country }) {
      this.phone.number = number.international;
      this.phone.valid = valid;
      this.phone.country = country && country.name;
    },
    onInputPhone2(formattedNumber, { number, valid, country }) {
      this.phone2.number = number.international;
      this.phone2.valid = valid;
      this.phone2.country = country && country.name;
    },
    changeLocale(locale) {
      this.$i18n.locale = locale.language;
      this.currentFlag = locale.flag;

      localStorage.setItem("lang", locale.language);
      localStorage.setItem("flag", locale.flag);
    },
    async checkAccount() {
      console.log("Nothing wrong!");
      this.isLoading = true;
      this.showError = false;
      this.candidate.telephone = this.phone.number
        .replace("+", "")
        .replace(/\s/g, "");
      try {
        if (this.validate()) {
          const { data } = await this.$apollo.mutate({
            mutation: CHECK_CADIDATE_ACCOUNT_MUTATION,
            variables: {
              email: this.candidate.email,
              name: this.candidate.name,
              telephone: this.candidate.telephone,
              locale: this.$i18n.locale,
            },
          });
          this.verificationCode = data.checkCandidateAccount;
          this.isRegister = false;
        }
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    },
    async reCheckAccount() {
      console.log("Nothing wrong!");
      this.isLoading = true;
      this.showError = false;
      this.candidate.telephone = this.phone.number
        .replace("+", "")
        .replace(/\s/g, "");
      try {
        const { data } = await this.$apollo.mutate({
          mutation: CHECK_CADIDATE_ACCOUNT_MUTATION,
          variables: {
            email: this.candidate.email,
            name: this.candidate.name,
            telephone: this.candidate.telephone,
            locale: this.$i18n.locale,
          },
        });
        this.verificationCode = data.checkCandidateAccount;
        this.isRegister = false;

        this.resended = true;
        setTimeout(() => {
          this.resended = false;
        }, 3000);
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    },
    resendedCode() {
      this.resended = false;
    },
    async createAccount() {
      this.isLoading = true;
      if (this.verificationCode == this.verificationCodeEnter) {
        try {
          this.candidate.telephone = this.phone.number
            .replace("+", "")
            .replace(/\s/g, "");
          this.candidate.telephone2 = this.phone2.number
            .replace("+", "")
            .replace(/\s/g, "");
          await this.$apollo.mutate({
            mutation: CREATE_CANDIDATE_ACCOUNT_MUTATION,
            variables: {
              ...this.candidate,
              interviewId: this.$route.query.interviewId,
              vacancyId: this.$route.query.vacancyId,
              sendedEmail: this.$route.query.sendedEmail,
            },
          });
          // await this.$router.push("/login");
          this.submit();
        } catch (error) {
          console.log(error);
          this.error = formatError(error.message);
          this.showError = true;
        } finally {
          this.isLoading = false;
        }
      } else {
        this.isLoading = false;
        this.error = "Codigo invalido";
        this.showError = true;
      }
    },
    validate() {
      return this.$refs.form.validate();
    },
    Terms_Conditions() {
      window.open("https://www.whirelab.com/terms&conditions", "_blank");
    },
    saveUserData(id, token) {
      localStorage.setItem(GC_USER_ID, id);
      localStorage.setItem(GC_AUTH_TOKEN, token);
      this.$root.$data.userId = localStorage.getItem(GC_USER_ID);
    },
    async submit() {
      try {
        await this.$apollo
          .mutate({
            mutation: LOGIN_MUTATION,
            variables: {
              email: this.candidate.email,
              password: this.candidate.password,
            },
          })
          .then(({ data }) => {
            const id = data.login.user.id;
            const token = data.login.token;
            this.saveUserData(id, token);
            this.setUserState(data.login.user);
            let loginUrl = localStorage.getItem("loginUrl")? "/candidate"+localStorage.getItem("loginUrl"): localStorage.getItem("loginParams")?"/candidate/":"/candidate"
            switch (data.login.user.type) {
              case "candidate":
                this.$router.push(this.$route.query.redirect || loginUrl);
                break;
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.wena {
  background: #141838;
}
</style>